
import { defineComponent } from 'vue';
import '@/components/view/common/dashboard/common.less';
import ItemTitle from '@/components/view/common/dashboard/ItemTitle.vue';
import CardIcon from '@/components/view/common/dashboard/CardIcon.vue';
import CardText from '@/components/view/common/dashboard/card-text.vue';
import CardRich from '@/components/view/common/dashboard/card-rich.vue';
import { initData } from '@/views/pm/home/old-community-home';
import router from '@/router';
import CardList from '@/components/view/common/dashboard/card-list.vue';

export default defineComponent({
    components: {
        ItemTitle,
        CardIcon,
        CardText,
        CardRich,
        CardList
    },
    setup() {
        const {
            communityInfoCardData,
            deviceInfoCardData,
            residentInfoCardData,
            otherCardData,
            communityData,
            weekData,
            detailList,
            lastDoorLogList,
            doorIndex,
            changeDoorList
        } = initData();
            // 跳转制定页面
        const goPath = (path: string) => {
            router.push(path);
        };
        console.log(12323);

        return {
            communityInfoCardData,
            deviceInfoCardData,
            residentInfoCardData,
            otherCardData,
            communityData,
            weekData,
            detailList,
            lastDoorLogList,
            doorIndex,
            changeDoorList,
            goPath
        };
    }
});
