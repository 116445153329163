
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        cardData: {},
        text: {
            type: Number
        },
        title: {
            type: String
        }
    }
});
