import {
    nextTick, ref
} from 'vue';
import * as Highcharts from 'highcharts';
import { communityForPmApi } from '@/api';
import { timeZoneOptions } from '@/data/src/time-zone';
import { propertyBaseRouter, propertySubRouter, propertyOldCommunitySubRouter } from '@/router';
import { DetailData, OldCommunityData, WeekData } from './all.type';

// communityInfo卡片配置
const communityInfoCardData = [
    {
        title: WordList.DarshbordBuild,
        icon: require('@/assets/image/building.png'),
        enable: false,
        name: 'builds'
    }, {
        title: WordList.ProperAllTextApartments,
        icon: require('@/assets/image/Rooms.png'),
        enable: false,
        name: 'rooms'
    }, {
        title: WordList.ProperAllTextInactiveApartmets,
        icon: require('@/assets/image/Rooms-gay.png'),
        enable: false,
        name: 'inactiveUsers'
    }, {
        title: WordList.ProperAllTextExpiredApartmets,
        icon: require('@/assets/image/Rooms-red.png'),
        enable: false,
        name: 'expriUsers'
    }
];

// deviceInfo卡片配置
const deviceInfoCardData = [
    {
        title: WordList.ProperAllTextDoorPhone,
        icon: require('@/assets/image/Door-Phones.png'),
        enable: true,
        name: 'doors',
        path: `/${propertyBaseRouter}/${propertySubRouter.device}?Type=0`
    }, {
        title: WordList.ProperAllTextDoorPhoneOff,
        icon: require('@/assets/image/Offline-Door-Phones.png'),
        enable: true,
        name: 'offdoors',
        path: `/${propertyBaseRouter}/${propertySubRouter.device}?Type=0&Status=0`
    }, {
        title: WordList.DarshbordIndoor,
        icon: require('@/assets/image/Indoor-Monitors.png'),
        enable: true,
        name: 'indoors',
        path: `/${propertyBaseRouter}/${propertySubRouter.device}?Type=2`
    }, {
        title: WordList.DarshbordIndoorOff,
        icon: require('@/assets/image/Offline-Indoor-Monitors.png'),
        enable: true,
        name: 'offindoor',
        path: `/${propertyBaseRouter}/${propertySubRouter.device}?Type=2&Status=0`
    }, {
        title: WordList.ProperAllTextAuditCategoryAccess,
        icon: require('@/assets/image/Access-Phones.png'),
        enable: true,
        name: 'access',
        path: `/${propertyBaseRouter}/${propertySubRouter.device}?Type=50`
    }, {
        title: WordList.ProperAllTextOffDeviceAccess,
        icon: require('@/assets/image/Offline-Access-Phones.png'),
        enable: true,
        name: 'offaccess',
        path: `/${propertyBaseRouter}/${propertySubRouter.device}?Type=50&Status=0`
    }
];

// residentInfo卡片配置
const residentInfoCardData = [
    {
        title: WordList.DarshbordEndUser,
        enable: true,
        name: 'endusers',
        path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResident}`
    },
    {
        title: WordList.DarshbordRegistered,
        enable: true,
        name: 'endrusers',
        path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResident}?state=1`
    }, {
        title: WordList.DarshbordUnregistered,
        enable: true,
        name: 'endurusers',
        path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResident}?state=0`
    },
    {
        title: WordList.DarshbordMobile,
        enable: false,
        name: 'apps'
    },
    {
        title: WordList.DarshbordPhone,
        enable: false,
        name: 'phones'
    }
];

// other 卡片配置
const otherCardData = [
    {
        title: WordList.DarshbordTmp,
        name: 'tmpkeys',
        icon: require('@/assets/image/Temp-Keys.png')
    }, {
        title: WordList.KeyAndCardRadioButtonRFCards,
        name: 'rfcards',
        icon: require('@/assets/image/RF-Cards.png')
    }, {
        title: WordList.DarshbordCall,
        name: 'calls',
        icon: require('@/assets/image/Calls.png')
    }
];

// 去除logo
const clearLogo = () => {
    const a = document.getElementsByClassName('highcharts-credits');
    for (let i = 0; i < a.length; i = +1) {
        a[i].parentNode!.removeChild(a[i]);
    }
};

// to day 柱状图
const createCountToDay = (countsData: number[] = []) => {
    Highcharts.chart('to-day-count', {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: [
                '2am', '4am', '6am', '8am', '10am', '12am', '2pm', '4pm', '6pm', '8pm', '10pm', '12pm'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            // head + 每个 point + footer 拼接成完整的 table
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: `${'<tr><td style="color:{series.color};padding:0">{series.name}: </td>'
            + '<td style="padding:0"><b>{point.y} '}${WordList.DarshbordTimes}</b></td></tr>`,
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                borderWidth: 0
            }
        },
        series: [{
            name: WordList.DarshbordReleases,
            data: countsData,
            type: 'column'
        }],
        colors: ['#18bbfd']
    });
    nextTick(() => {
        clearLogo();
    });
};

// to year 折线图
const createCountToYear = (countsData: number[] = []) => {
    Highcharts.chart('to-year-count',
        {
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: [
                    WordList.DateInputMonthJan,
                    WordList.DateInputMonthFeb,
                    WordList.DateInputMonthMar,
                    WordList.DateInputMonthApr,
                    WordList.DateInputMonthMay,
                    WordList.DateInputMonthJun,
                    WordList.DateInputMonthJul,
                    WordList.DateInputMonthAug,
                    WordList.DateInputMonthSep,
                    WordList.DateInputMonthOct,
                    WordList.DateInputMonthNov,
                    WordList.DateInputMonthDec
                ],
                tickmarkPlacement: 'on'
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                shared: true
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#ffffff',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#ffffff'
                    }
                }
            },
            series: [
                {
                    name: WordList.DarshbordTotalReleaes,
                    data: countsData,
                    type: 'area'
                }
            ]
        });
    nextTick(() => {
        clearLogo();
    });
};

const initData = () => {
    // 社区数据，卡片数据和图标数据
    const communityData = ref<OldCommunityData | {}>({});
    communityForPmApi.getPmCommunityData((res: OldCommunityData) => {
        communityData.value = res;
        nextTick(() => {
            if (document.getElementById('to-day-count')) {
                createCountToDay(res.todayRelease);
                createCountToYear(res.yearRelease);
            }
        });
    });

    // card-list数据
    const weekData = ref<Array<{ label1: string; label2: string }>>([]);
    communityForPmApi.getPmWeekData((res: WeekData) => {
        const tempList = [
            {
                label1: WordList.DarshbordReleases,
                label2: res.door
            },
            {
                label1: WordList.DarshbordCall,
                label2: res.call
            },
            {
                label1: WordList.DarshbordEndUser2,
                label2: res.users
            },
            {
                label1: WordList.DarshbordTmp,
                label2: res.tmpKey
            }
        ];
        weekData.value = tempList;
    });

    const detailList = ref<Array<{ label1: string; label2: string }>>([]);
    communityForPmApi.getPMCommunityDetail((res: DetailData) => {
        let timeZoneTemp = '';
        timeZoneOptions.forEach((timeZone) => {
            if (timeZone.value === res.TimeZone) {
                timeZoneTemp = timeZone.label;
            }
        });
        const tempList = [
            {
                label1: WordList.TabelPersonUserInHtmlName,
                label2: res.Location
            },
            {
                label1: WordList.TabelCommunitiesStreet,
                label2: res.Street
            },
            {
                label1: WordList.TabelCommunitiesTimeZone,
                label2: timeZoneTemp
            }
        ];
        detailList.value = tempList;
    });

    const lastDoorLogList = ref<{ label1: string }[][]>([]);
    communityForPmApi.getLastDoorLog((res: Array<object>) => {
        const temp = [];
        for (let i = 0; i < res.length; i += 1) {
            const tmp: Array<{ label1: string }> = [];
            const data = res[i];
            Object.keys(data).forEach((key) => {
                if (key === 'CaptureType' || key === 'ID') return;
                tmp.push({ label1: res[i][key as keyof typeof data] });
            });
            temp.push(tmp);
        }
        lastDoorLogList.value = temp;
    });

    const doorIndex = ref(0);
    const changeDoorList = (type: 1 | 2) => {
        if (type === 1 && doorIndex.value === 0) {
            return true;
        }
        if (type === 2 && doorIndex.value === lastDoorLogList.value.length - 1) {
            return true;
        }
        if (type === 1) {
            doorIndex.value -= 1;
        }
        if (type === 2) {
            doorIndex.value += 1;
        }
        return true;
    };

    return {
        communityInfoCardData,
        deviceInfoCardData,
        residentInfoCardData,
        otherCardData,
        communityData,
        weekData,
        detailList,
        lastDoorLogList,
        doorIndex,
        changeDoorList
    };
};

export default null;
export {
    initData
};
